import React, { FC, PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';

import styles from './layout.module.css';
import { useAppStore } from '../../store/app';
import { Footer } from '../footer/footer';
import { PageSpinner } from '../page-spinner/page-spinner';

type Props = {
  fetching?: boolean;
  withFloatingHeaderBg?: boolean;
  withFixedHeaderBg?: boolean;
  showFooter?: boolean;
  spinnerDebounceTimeout?: number;
} & PropsWithChildren;

export const Layout: FC<Props> = observer(
  ({
    children,
    fetching,
    withFloatingHeaderBg,
    withFixedHeaderBg,
    showFooter = true,
    spinnerDebounceTimeout,
  }) => {
    const {
      headerHeight,
      isSocialsVisible,
      isGameWithBreak,
      telegramSupportLink,
    } = useAppStore();
    return (
      <div
        className={cn(
          styles.layout,
          withFixedHeaderBg && styles.withFixedHeaderBg
        )}
      >
        {fetching && <PageSpinner debounceTimeout={spinnerDebounceTimeout} />}
        {withFloatingHeaderBg && (
          <>
            <div
              className={styles.headerBg}
              style={{ paddingTop: headerHeight }}
            />
            <div className={styles.bg} style={{ top: headerHeight }} />
          </>
        )}
        {withFixedHeaderBg && <div className={styles.fixedHeaderBg} />}
        <div
          className={cn(
            styles.contentContainer,
            withFloatingHeaderBg && styles.alignStart
          )}
        >
          {children}
        </div>
        {showFooter && (
          <div className={styles.footerContainer}>
            <Footer
              isSocialsVisible={isSocialsVisible}
              isLogoVisible={isSocialsVisible}
              isDisclaimerVisible={isGameWithBreak}
              telegramSupportLink={telegramSupportLink}
            />
          </div>
        )}
      </div>
    );
  }
);
