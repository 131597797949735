import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styles from './footer.module.css';
import logoWhiteImg from './logo-white.svg';
import { Button } from '../../ui/button/button';
import { TelegramIcon } from '../../ui/icons/telegram/telegram';
import { VkIcon } from '../../ui/icons/vk/vk';
import { InstagramIcon } from '../../ui/icons/instagram/instagram';
import {
  INSTAGRAM_LINK,
  QH,
  TELEGRAM_LINK,
  TELEGRAM_SUPPORT_LINK,
  VK_LINK,
} from '../../constants';

type FooterProps = {
  isSocialsVisible?: boolean;
  isLogoVisible?: boolean;
  isDisclaimerVisible?: boolean;
  telegramSupportLink?: string;
};

export const Footer: FC<FooterProps> = ({
  isSocialsVisible,
  isDisclaimerVisible,
  telegramSupportLink = TELEGRAM_SUPPORT_LINK,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.chat}>
        <a href={telegramSupportLink} target="_blank" rel="noreferrer">
          <Button className={styles.button}>
            <span className={styles.buttonContent}>
              <TelegramIcon />
              <span className={styles.buttonText}>{t('footer.support')}</span>
            </span>
          </Button>
        </a>
        {isDisclaimerVisible && (
          <div className={styles.chatText}>
            <Trans>{t('footer.disclaimer')}</Trans>
          </div>
        )}
      </div>
      {isSocialsVisible && (
        <div className={styles.socials}>
          <ul className={styles.socialsList}>
            <li>
              <a href={TELEGRAM_LINK} target="_blank" rel="noreferrer">
                <TelegramIcon width={20} height={16} />
                <span className={styles.socialsListText}>Telegram</span>
              </a>
            </li>
            <li>
              <a href={VK_LINK} target="_blank" rel="noreferrer">
                <VkIcon />
                <span className={styles.socialsListText}>Vkontakte</span>
              </a>
            </li>
            <li>
              <a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer">
                <InstagramIcon />
                <span className={styles.socialsListText}>Instagram</span>
              </a>
            </li>
          </ul>
        </div>
      )}
      <div className={styles.copy}>
        &copy; 2024 <img className={styles.logo} src={logoWhiteImg} alt={QH} />
      </div>
    </div>
  );
};
